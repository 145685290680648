if (document.querySelector('.case-toggler') && document.querySelector('.team-list__filter')) {
    const labelToglers = document.querySelectorAll('.case-toggler label');
    const inputToglers = document.querySelectorAll('.case-toggler input');
    const containers = document.querySelectorAll('.team-list__filter');

    inputToglers.forEach((input, i) => {
        if (input.checked) {
            containers[i].classList.add('is-active');
        }
    })

    labelToglers.forEach((label, i) => {
        label.addEventListener('click', (e) => {
            e.stopPropagation()

            if (i) {
                containers[0].classList.remove('is-active');
                containers[1].classList.add('is-active');
            } else {
                containers[1].classList.remove('is-active');
                containers[0].classList.add('is-active');
            }
        })
    })
}

