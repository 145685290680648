import Plyr from 'plyr';

if (document.querySelector('.js-video-player')) {
  const videos = document.querySelectorAll('.js-video-player');

  videos.forEach((video) => {
    const player = new Plyr(video, {
      controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'duration',
        'mute',
        'volume',
        'captions',
        'fullscreen',
      ],
    });

    player.on('play', () => {
      if (video.classList.contains('is-hide'))
        video.classList.remove('is-hide');
    });
  });
}
