import Aos from 'aos';

Aos.init({
  startEvent: 'load',
  offset: 40,
  delay: 50,
  duration: 500,
  easing: 'ease',
  once: true,
});
