import noUiSlider from 'nouislider';

const singleRangeSlider = () => {
  const singleRange = document.getElementsByClassName(
    'single-range-default'
  )[0];
  if (singleRange) {
    const slider = singleRange.querySelector('#single-range-default');
    const max = +slider.dataset.max;
    const min = +slider.dataset.min;
    const unit = slider.dataset?.unit ?? '£';
    const customUnit = slider.dataset?.customUnit ?? ' ';
    const step = +slider.dataset.step;
    const inputsHidden = singleRange.querySelector(
      '.single-range-hidden-input'
    );
    const inputInfo = singleRange.querySelector('.hero__range-info');
    const startValue = +inputsHidden.value;

    noUiSlider.create(slider, {
      start: [startValue],
      connect: 'lower',
      step: step,
      range: {
        min: min,
        max: max,
      },
    });

    slider.noUiSlider.on('update', function (values, handle) {
      inputInfo.textContent = `${
        unit + Math.round(values[handle])
      } ${customUnit}`;

      inputsHidden.value = Math.round(values[handle]);
    });
  }
};
singleRangeSlider();
