import Swiper, {
  Autoplay,
  EffectCreative,
  EffectFade,
  FreeMode,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Thumbs,
} from 'swiper';
import { swiperOnResize } from '../utils/swiperOnResize.js';

const setTimelineOpacity = ({ isBeginning, isEnd }, targetEl) => {
  if (!isBeginning) targetEl.classList.remove('is-start');
  if (!isEnd) targetEl.classList.remove('is-end');

  if (isBeginning) targetEl.classList.add('is-start');
  if (isEnd) targetEl.classList.add('is-end');
};

const timelineCol = document.querySelector('.timeline__col');

const thumbSwiper = new Swiper('.timeline__slider', {
  modules: [FreeMode, Navigation, Scrollbar, Mousewheel, Autoplay],
  direction: 'vertical',
  slidesPerView: 'auto',
  spaceBetween: 20,
  mousewheel: true,
  speed: 500,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  scrollbar: {
    el: '.timeline__scrollbar',
    draggable: true,
  },
  breakpoints: {
    1024: {
      spaceBetween: 40,
    },
  },
  on: {
    init: ({ isBeginning, isEnd }) => {
      setTimelineOpacity({ isBeginning, isEnd }, timelineCol);
    },
    slideChange: ({ isBeginning, isEnd }) => {
      setTimelineOpacity({ isBeginning, isEnd }, timelineCol);
    },
  },
});

if (document.querySelector('.sectors__slider')) {
  const swiper = new Swiper('.sectors__slider', {
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 1,
    spaceBetween: 16,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    pagination: {
      el: '.sectors .swiper-pagination',
      type: 'fraction',
    },
    navigation: {
      prevEl: '.sectors .slider-btn.prev',
      nextEl: '.sectors .slider-btn.next',
    },
  });
}

if (document.querySelector('.news__slider')) {
  const swiper = new Swiper('.news__slider', {
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: 1,
    spaceBetween: 16,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.news .slider-btn.prev',
      nextEl: '.news .slider-btn.next',
    },
    breakpoints: {
      658: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
    },
  });
}

if (document.querySelector('.testimonials__slider')) {
  new Swiper('.testimonials__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 8,
    initialSlide: 1,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.testimonials .slider-btn.prev',
      nextEl: '.testimonials .slider-btn.next',
    },
    breakpoints: {
      658: {
        spaceBetween: 25,
      },
      1024: {
        spaceBetween: 48,
      },
    },
  });
}
if (document.querySelector('.opportunities__slider')) {
  new Swiper('.opportunities__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 16,
    initialSlide: 1,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.opportunities .slider-btn.prev',
      nextEl: '.opportunities .slider-btn.next',
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
    },
  });
}
if (document.querySelector('.team__slider')) {
  new Swiper('.team__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 16,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.team .slider-btn.prev',
      nextEl: '.team .slider-btn.next',
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      900: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
    },
  });
}
if (document.querySelector('.studies__slider')) {
  new Swiper('.studies__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 16,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.studies .slider-btn.prev',
      nextEl: '.studies .slider-btn.next',
    },
    breakpoints: {
      769: {
        slidesPerView: 2,
        spaceBetween: 32,
      },
    },
  });
}
if (document.querySelector('.stories__slider')) {
  new Swiper('.stories__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.stories .slider-btn.prev',
      nextEl: '.stories .slider-btn.next',
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 72,
      },
    },
  });
}

if (document.querySelector('.how-work__slider')) {
  swiperOnResize('(max-width: 1023px)', '.how-work__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 16,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.how-work .slider-btn.prev',
      nextEl: '.how-work .slider-btn.next',
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
    },
  });
}

if (document.querySelector('.footer__slider')) {
  swiperOnResize('(max-width: 1023px)', '.footer__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 24,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.footer .slider-btn.prev',
      nextEl: '.footer .slider-btn.next',
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 0,
      },
    },
  });
}

if (document.querySelector('.solutions__slider')) {
  const thumbSwiper = new Swiper('.solutions__thumb', {
    modules: [FreeMode, Navigation, Thumbs, Scrollbar, Autoplay],
    slidesPerView: 'auto',
    watchSlidesProgress: true,
    spaceBetween: 8,
    direction: 'vertical',
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
  });

  const mainSlider = new Swiper('.solutions__slider', {
    modules: [Autoplay, FreeMode, Navigation, Thumbs, EffectFade],
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    thumbs: {
      swiper: thumbSwiper,
    },
    autoHeight: true,
    allowTouchMove: false,
    noSwiping: true,
  });
}

window.initTouchSlider = function initTouchSlider() {
  const thumbSwiper = new Swiper('.popup-upload-cv__wrapper .touch__thumb', {
    modules: [FreeMode, Navigation, Thumbs, Scrollbar, Autoplay],
    slidesPerView: 2,
    watchSlidesProgress: true,
    breakpoints: {
      640: {
        slidesPerView: 'auto',
      },
    },
  });

  const mainSlider = new Swiper('.popup-upload-cv__wrapper .touch__slider', {
    modules: [Autoplay, FreeMode, Navigation, Thumbs, EffectFade],
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    thumbs: {
      swiper: thumbSwiper,
    },
    autoHeight: true,
    allowTouchMove: false,
    noSwiping: true,
  });
};

if (document.querySelector('.touch__slider')) {
  const thumbSwiper = new Swiper('.touch__thumb', {
    modules: [FreeMode, Navigation, Thumbs, Scrollbar, Autoplay],
    slidesPerView: 2,
    watchSlidesProgress: true,
    // spaceBetween: 10,
    // speed: 500,
    // autoplay: {
    //     delay: 4000,
    //     disableOnInteraction: false,
    //     pauseOnMouseEnter: true,
    // },
    // scrollbar: {
    //     el: ".solutions__scrollbar",
    //     draggable: true,
    // },
    breakpoints: {
      640: {
        slidesPerView: 'auto',
      },
      // 768: {
      //     slidesPerView: 3,
      //     spaceBetween: 20,
      //     direction: "horizontal",
      // },
      // 1024: {
      //     slidesPerView: 'auto',
      //     spaceBetween: 8,
      //     direction: "vertical",
      // },
    },
  });

  const mainSlider = new Swiper('.touch__slider', {
    modules: [Autoplay, FreeMode, Navigation, Thumbs, EffectFade],
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    thumbs: {
      swiper: thumbSwiper,
    },
    autoHeight: true,
    allowTouchMove: false,
    noSwiping: true,
  });
}

if (document.querySelector('.team-slider__slider')) {
  const thumbSwiper = new Swiper('.team-slider__thumb', {
    modules: [FreeMode, Navigation, Thumbs, Scrollbar, Autoplay],
    slidesPerView: 1,
    watchSlidesProgress: true,
    spaceBetween: 25,

    // scrollbar: {
    //     el: ".transformation__scrollbar",
    //     draggable: true,
    // },
    breakpoints: {
      568: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        spaceBetween: 60,
        slidesPerView: 3,
      },
    },
  });

  const mainSlider = new Swiper('.team-slider__slider', {
    modules: [Autoplay, FreeMode, Navigation, Thumbs, EffectFade],
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    thumbs: {
      swiper: thumbSwiper,
    },
    navigation: {
      prevEl: '.team-slider .slider-btn.prev',
      nextEl: '.team-slider .slider-btn.next',
    },
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
  });
}

if (document.querySelector('.transformation__slider')) {
  const thumbSwiper = new Swiper('.transformation__thumb', {
    modules: [FreeMode, Navigation, Thumbs, Scrollbar, Autoplay],
    slidesPerView: 1,
    watchSlidesProgress: true,
    spaceBetween: 10,
    direction: 'horizontal',

    scrollbar: {
      el: '.transformation__scrollbar',
      draggable: true,
    },
    breakpoints: {
      500: {
        slidesPerView: 2,
        spaceBetween: 15,
        direction: 'horizontal',
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
        direction: 'horizontal',
      },
      1024: {
        direction: 'vertical',
        slidesPerView: 'auto',
        spaceBetween: 0,
        autoplay: false,
        allowTouchMove: false,
        noSwiping: true,
      },
    },
  });

  const mainSlider = new Swiper('.transformation__slider', {
    modules: [Autoplay, FreeMode, Navigation, Thumbs, EffectFade],
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    thumbs: {
      swiper: thumbSwiper,
    },
    // speed: 500,
    // autoplay: {
    //   delay: 4000,
    //   disableOnInteraction: false,
    //   pauseOnMouseEnter: true,
    // },
    allowTouchMove: false,
    noSwiping: true,
  });

  thumbSwiper.on('slideChange', ({ activeIndex }) =>
    mainSlider.slideTo(activeIndex)
  );
}

if (document.querySelector('.rewarded__slider')) {
  const thumbSwiper = new Swiper('.rewarded__thumb', {
    modules: [FreeMode, Navigation, Thumbs, Scrollbar, Autoplay],
    slidesPerView: 1,
    watchSlidesProgress: true,
    spaceBetween: 10,
    direction: 'horizontal',

    scrollbar: {
      el: '.rewarded__scrollbar',
      draggable: true,
    },
    breakpoints: {
      500: {
        slidesPerView: 2,
        spaceBetween: 15,
        direction: 'horizontal',
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
        direction: 'horizontal',
      },
      1024: {
        direction: 'vertical',
        slidesPerView: 'auto',
        spaceBetween: 0,
        autoplay: false,
        allowTouchMove: false,
        noSwiping: true,
      },
    },
  });

  const mainSlider = new Swiper('.rewarded__slider', {
    modules: [Autoplay, FreeMode, Navigation, Thumbs, EffectFade],
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    thumbs: {
      swiper: thumbSwiper,
    },
    // speed: 500,
    // autoplay: {
    //   delay: 4000,
    //   disableOnInteraction: false,
    //   pauseOnMouseEnter: true,
    // },
  });
}

if (document.querySelector('.locations__main-slider')) {
  const thumbSwiper = new Swiper('.locations__thumb', {
    modules: [FreeMode, Navigation, Thumbs, Scrollbar, Autoplay],
    slidesPerView: 'auto',
    watchSlidesProgress: true,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
  });

  const mainSlider = new Swiper('.locations__main-slider', {
    modules: [Autoplay, FreeMode, Navigation, Thumbs, EffectFade],
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    thumbs: {
      swiper: thumbSwiper,
    },
    autoHeight: true,
    allowTouchMove: false,
    noSwiping: true,
    initialSlide: 2,
    on: {
      init: (swiper) => {
        const defaultActiveIdx = swiper.slides.findIndex((slide) =>
          slide.classList.contains('start-slide')
        );

        swiper.slideTo(defaultActiveIdx);
      },
    },
  });

  const innerSliders = document.querySelectorAll('.locations__inner-slider');

  innerSliders.forEach((slider, i) => {
    const innerImgWraps = slider.nextElementSibling;
    const imgs = innerImgWraps.querySelectorAll('.img');

    const prevBtn = slider.querySelector('.prev');
    const nextBtn = slider.querySelector('.next');

    const innerSlider = new Swiper(slider, {
      modules: [Autoplay, Navigation, EffectFade],
      slidesPerView: 1,
      spaceBetween: 0,
      effect: 'fade',
      navigation: {
        prevEl: prevBtn,
        nextEl: nextBtn,
      },
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      on: {
        init: (swiper) => {
          imgs[swiper.activeIndex].classList.add('active');
          // const locationBtn = swiper.el.querySelector('.locations__btn');
          // const { link } = swiper.slides[swiper.activeIndex].dataset;
          //
          // if (locationBtn) locationBtn.setAttribute('href', link);
        },
        slideChange: (swiper) => {
          imgs.forEach((img) => {
            img.classList.remove('active');
          });
          imgs[swiper.activeIndex].classList.add('active');
          // const locationBtn = swiper.el.querySelector('.locations__btn');
          // const { link } = swiper.slides[swiper.activeIndex].dataset;
          //
          // if (locationBtn) locationBtn.setAttribute('href', link);
        },
      },
    });
  });
}

if (document.querySelector('.interested__slider')) {
  const swiper = new Swiper('.interested__slider', {
    modules: [Autoplay, Navigation, EffectFade],
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    navigation: {
      prevEl: '.interested .slider-btn.prev',
      nextEl: '.interested .slider-btn.next',
    },
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
  });
}

if (document.querySelector('.news-slider__slider')) {
  const swiper = new Swiper('.news-slider__slider', {
    modules: [Autoplay, Navigation, Pagination, EffectFade],
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    navigation: {
      prevEl: '.news-slider .slider-btn.prev',
      nextEl: '.news-slider .slider-btn.next',
    },
    pagination: {
      el: '.news-slider .swiper-pagination',
      type: 'fraction',
    },
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
  });
}

if (document.querySelector('.included__slider')) {
  const swiper = new Swiper('.included__slider', {
    modules: [Autoplay, Navigation, EffectFade],
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    navigation: {
      prevEl: '.included .slider-btn.prev',
      nextEl: '.included .slider-btn.next',
    },
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
  });
}

if (document.querySelector('.hero__slider')) {
  const swiper = new Swiper('.hero__slider', {
    modules: [Autoplay, Navigation, EffectFade],
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    navigation: {
      prevEl: '.hero .slider-btn.prev',
      nextEl: '.hero .slider-btn.next',
    },
    allowTouchMove: false,
    noSwiping: true,
    speed: 600,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
  });
}

if (document.querySelector('.advantages__slider')) {
  swiperOnResize('(max-width: 1023px)', '.advantages__slider', {
    modules: [Autoplay, Navigation],
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      568: {
        slidesPerView: 2,
      },
      900: {
        slidesPerView: 3,
      },
    },
    navigation: {
      prevEl: '.advantages .slider-btn.prev',
      nextEl: '.advantages .slider-btn.next',
    },
  });
}

if (document.querySelector('.types__slider')) {
  swiperOnResize('(max-width: 1023px)', '.types__slider', {
    modules: [Autoplay, Navigation],
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      568: {
        slidesPerView: 2,
      },
      900: {
        slidesPerView: 3,
      },
    },
    navigation: {
      prevEl: '.types .slider-btn.prev',
      nextEl: '.types .slider-btn.next',
    },
  });
}

if (document.querySelector('.benefits__slider')) {
  swiperOnResize('(max-width: 1023px)', '.benefits__slider', {
    modules: [Autoplay, Navigation],
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      568: {
        slidesPerView: 2,
      },
      900: {
        slidesPerView: 3,
      },
    },
    navigation: {
      prevEl: '.benefits .slider-btn.prev',
      nextEl: '.benefits .slider-btn.next',
    },
  });
}
if (document.querySelector('.values__slider')) {
  swiperOnResize('(max-width: 1023px)', '.values__slider', {
    modules: [Autoplay, Navigation],
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      568: {
        slidesPerView: 2,
      },
      900: {
        slidesPerView: 3,
      },
    },
    navigation: {
      prevEl: '.values .slider-btn.prev',
      nextEl: '.values .slider-btn.next',
    },
  });
}

if (document.querySelector('.our-sectors__slider')) {
  const thumbSwiper = new Swiper('.our-sectors__thumb', {
    modules: [FreeMode, Navigation, Thumbs, Scrollbar, Autoplay],
    slidesPerView: 1,
    watchSlidesProgress: true,
    spaceBetween: 25,
    direction: 'horizontal',
    speed: 500,
    scrollbar: {
      el: '.our-sectors__scrollbar',
      draggable: true,
    },
    breakpoints: {
      500: {
        slidesPerView: 2,
        spaceBetween: 25,
        direction: 'horizontal',
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
        direction: 'horizontal',
      },
      1024: {
        direction: 'vertical',
        slidesPerView: 'auto',
        spaceBetween: 20,
        autoplay: false,
        allowTouchMove: false,
        noSwiping: true,
      },
    },
  });

  const mainSlider = new Swiper('.our-sectors__slider', {
    modules: [Autoplay, FreeMode, Navigation, Thumbs, EffectFade],
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    thumbs: {
      swiper: thumbSwiper,
    },
    // autoplay: {
    //   delay: 4000,
    //   disableOnInteraction: false,
    //   pauseOnMouseEnter: true,
    // },
    allowTouchMove: false,
    noSwiping: true,
  });

  thumbSwiper.on('slideChange', ({ activeIndex }) =>
    mainSlider.slideTo(activeIndex)
  );
}

if (document.querySelector('.gallery__slider')) {
  const gallerySlidersArr = document.querySelectorAll('.gallery__slider');
  gallerySlidersArr.forEach((sliderEl) => {
    const swiper = new Swiper(sliderEl, {
      grabCursor: true,
      spaceBetween: 12,
      loop: true,
      slidesPerView: 'auto',
    });
  });
}
