// Light animation by scroll
const animateInViewElements = document.querySelectorAll('[data-aiv]');

const observer = new IntersectionObserver(
  (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('in-view');
        observer.unobserve(entry.target);
      }
    });
  },
  { threshold: 0 }
);

animateInViewElements.forEach((item) => {
  const { dataset } = item;

  item.style.setProperty(
    '--duration',
    dataset.aivTime ? `${dataset.aivTime}ms` : '500ms'
  );
  item.style.setProperty(
    '--delay',
    dataset.aivDelay ? `${dataset.aivDelay}ms` : '0ms'
  );

  observer.observe(item);
});
