function accordionFunc(accordion) {
  console.log(123);
  const toggleArr = accordion.querySelectorAll('.accordion-toggle');
  const contentArr = accordion.querySelectorAll('.accordion-content');

  toggleArr[0].classList.add('active');
  contentArr[0].classList.add('active');

  toggleArr.forEach((item, i) => {
    const btn = item.querySelector('.accordion-btn');
    const content = contentArr[i];

    item.addEventListener('click', (event) => {
      console.log(223);
      if (event.target !== btn) {
        openAccordion(item);
        console.log(323);
      } else if (event.target === btn && item.classList.contains('active')) {
        item.classList.remove('active');
        content.classList.remove('active');
        content.style.maxHeight = 0;
      } else {
        openAccordion(item);
      }
    });

    function openAccordion(item) {
      for (let j = 0; j < toggleArr.length; j++) {
        toggleArr[j].classList.remove('active');
        contentArr[j].classList.remove('active');
        contentArr[j].style.maxHeight = 0;
      }

      item.classList.add('active');
      content.classList.add('active');
      content.style.maxHeight = `${itemCalcMaxHeight(item, content)}px`;
    }

    function itemCalcMaxHeight(item, content) {
      const children = Array.from(content.children);
      const totalHeight = children.reduce((acc, child) => {
        const childHeight = child.offsetHeight;
        return acc + childHeight;
      }, 0);
      return totalHeight + 100;
    }
  });
}

const sectorsAccordion = document.getElementsByClassName(
  'our-sectors__accordion'
)[0];
if (sectorsAccordion) {
  accordionFunc(sectorsAccordion);
}

const transformationAccordion = document.getElementsByClassName(
  'transformation__accordion'
)[0];
if (transformationAccordion) {
  accordionFunc(transformationAccordion);
}

const rewardedAccordion = document.getElementsByClassName(
  'rewarded__accordion'
)[0];
if (rewardedAccordion) {
  accordionFunc(rewardedAccordion);
}
