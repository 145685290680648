import './utils/simplebar.utils.js';

import './libs/simplebar.lib.js';
import './libs/aos.lib.js';
import './libs/swiper.lib.js';
import './libs/countUp.lib.js';
import './libs/marquee.lib.js';
import './libs/plyr.lib.js';
import './libs/choices.lib.js';
import './libs/singleRange.lib.js';

import './utils/vh.utils.js';

import './components/clients.component.js';
import './components/header.component.js';
import './components/details.component.js';
import './components/clock.component.js';
import './components/brandsAccordions.component.js';
import './components/customAccordion.component.js';
import './components/headerDropdown.component.js';
import './components/uploadFile.component.js';
import './components/jobItemsVariant.component.js';
import './components/inputs.component.js';
import './components/animate-in-view.component.js';
import './components/teamList.component.js';

import './components/accordions.component.js';

import marquee from 'vanilla-marquee/dist/vanilla-marquee.js';
import { is_touch_enabled } from './utils/navigator.utils.js';
if (document.getElementById('marquee_1')) {
  new marquee(document.getElementById('marquee_1'), {
    duplicated: true,
    gap: 12,
    speed: 20,
    pauseOnHover: true,
    recalcResize: false,
    startVisible: true,
  });
}

if (document.getElementById('marquee_2')) {
  new marquee(document.getElementById('marquee_2'), {
    duplicated: true,
    gap: 12,
    speed: 20,
    pauseOnHover: true,
    direction: 'right',
    recalcResize: false,
    startVisible: true,
  });
}

window.telOutput = function telOutput() {
  const userTelBtnList = document.querySelectorAll('.team__soc.team__soc--tel');

  const hideAllPhones = ({ target }) => {
    const noTouchPhoneDivList = document.querySelectorAll('.no-touch-phone');

    if (!target.classList.contains('no-touch-phone')) {
      noTouchPhoneDivList.forEach((item) => item.classList.remove('active'));
    }
  };

  if (!is_touch_enabled()) {
    document.addEventListener('click', hideAllPhones);

    userTelBtnList.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        hideAllPhones(e);

        const socWrap = item.closest('.team__soc-wrap');

        if (!socWrap) {
          return;
        }

        socWrap.addEventListener('click', (e) => {
          e.stopPropagation();
        });

        const innerPhoneDiv = item.querySelector('.no-touch-phone');
        const innerTel = item.querySelector('span.hidden').textContent;

        if (!innerPhoneDiv) {
          const div = document.createElement('DIV');
          div.classList.add('no-touch-phone');

          div.textContent = innerTel;

          socWrap.appendChild(div);

          div.classList.add('active');
        } else {
          innerPhoneDiv.textContent = innerTel;
          innerPhoneDiv.classList.add('active');
        }
      });
    });
  }
};

telOutput();
