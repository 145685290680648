function pagePagination() {
  if (document.querySelector('.details__nav a')) {
    const bullets = document.querySelectorAll('.details__nav a');
    let gap = innerWidth < 1024 ? 200 : 400;

    window.addEventListener('scroll', (e) => {
      bullets.forEach((bullet, i) => {
        let section = document.querySelector(bullet.hash).offsetTop;
        if (window.scrollY + gap > section) {
          bullets.forEach((bullet) => {
            bullet.classList.remove('is-active');
          });
          bullet.classList.add('is-active');
        }
      });
    });
  }
}

pagePagination();
