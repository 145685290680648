const jobItems = document.querySelectorAll('.job-list__item');

jobItems.forEach((item) => {
  const switchCheckbox = item.querySelector('.job-list__name-switch input');
  const checkedContent = item.querySelector('[data-checked]');
  const uncheckedContent = item.querySelector('[data-unchecked]');


  if (item.querySelector('[data-first]')) {
    const firstText = item.querySelector('[data-first]').textContent;
    const secondText = item.querySelector('[data-sec]').textContent;

    if (switchCheckbox && firstText) {
      switchCheckbox.addEventListener('change', () => {
        switchCheckbox.value = switchCheckbox.checked ? secondText : firstText;
      })
    }
  }


  if (switchCheckbox && checkedContent) {
    switchCheckbox.addEventListener('change', ({ target }) => {
      if (target.checked) {
        checkedContent.style.display = 'flex';
        uncheckedContent.style.display = 'none';
      } else {
        checkedContent.style.display = 'none';
        uncheckedContent.style.display = 'flex';
      }
    });
  }
});
