function selectTimeZone(region, timeZone, container) {
  if (container.length) {
    const currentHours = new Date().toLocaleTimeString(region, {
      timeZone,
      hour12: false,
      hour: '2-digit',
    });
    const currentMinutes = new Date().toLocaleTimeString(region, {
      timeZone,
      minute: '2-digit',
    });
    const clockElement = document.querySelector(container);
    clockElement.innerHTML = `
            ${currentHours.length < 2 ? '0' + currentHours : currentHours}
            <span class="colon"></span>
            ${currentMinutes.length < 2 ? '0' + currentMinutes : currentMinutes}
        `;
  }
}

function updateClock() {
  if (document.querySelector('.locations__time-box--uk')) {
    selectTimeZone('en-GB', 'Europe/London', '.locations__time-box--uk');
  }
  if (document.querySelector('.locations__time-box--ire')) {
    selectTimeZone('en-IE', 'Europe/Dublin', '.locations__time-box--ire');
  }
  if (document.querySelector('.locations__time-box--us')) {
    selectTimeZone('en-US', 'America/New_York', '.locations__time-box--us');
  }
  if (document.querySelector('.locations__time-box--eu')) {
    selectTimeZone('en-US', 'Europe/Berlin', '.locations__time-box--eu');
  }
}

if (document.querySelectorAll('.locations__time-box').length) {
  setInterval(updateClock, 1000);
  updateClock();
}
