import Choices from 'choices.js';

const choicesArr = document.querySelectorAll('.js-choices');

if (choicesArr) {
  choicesArr.forEach((select) => {
    new Choices(select, {
      allowHTML: true,
      searchEnabled: false,
      placeholder: true,
      placeholderValue: null,
      searchPlaceholderValue: null,
      shouldSort: false,
      shouldSortItems: false,
    });
  });

  //   const choicesWrappers = document.querySelectorAll('.choices');
  //   choicesWrappers.forEach((choiceWrap) => {
  //     choiceWrap.addEventListener('click', () => {
  //       if (!choiceWrap.querySelector('.custom-simplebar-wrapper')) {
  //         const selectSimplebar = choiceWrap.querySelector(
  //           '.choices__list--dropdown .choices__list'
  //         );
  //         const div = document.createElement('div');
  //         div.classList.add('custom-simplebar-wrapper');
  //         const content = selectSimplebar.innerHTML;
  //         selectSimplebar.innerHTML = '';
  //         div.setAttribute('data-simplebar', '');
  //         div.innerHTML = content;
  //         selectSimplebar.appendChild(div);
  //       }
  //     });
  //   });
}
