window.uploadFile = function uploadFile() {
  if (document.querySelector('[data-upload-file-animation]')) {
    const uploadFileBlocksArr = document.querySelectorAll(
      '[data-upload-file-animation]'
    );
    uploadFileBlocksArr.forEach((fileUpload) => {
      const uploadInput = fileUpload.querySelector('input');
      const uploadLabel = fileUpload.querySelector('.meta-upload-file__name');

      fileUpload.addEventListener('dragover', function () {
        this.classList.add('drag');
        this.classList.remove('drop', 'done');
      });

      fileUpload.addEventListener('dragleave', function () {
        this.classList.remove('drag');
      });

      fileUpload.addEventListener('drop', start, false);
      fileUpload.addEventListener('change', start, false);

      function start() {
        this.classList.remove('drag');
        this.classList.add('drop');
        setTimeout(() => this.classList.add('done'), 500);
      }

      uploadInput.addEventListener('change', function () {
        let dots;
        const arr = this.files[0].name.split(".");
        arr[0].length > 6 ? (dots = "...") : (dots = ".");
        const name = arr[0].substring(0, 10) + dots + arr[1];
        uploadLabel.innerHTML = name;
    });
    });
  }
};
uploadFile();

