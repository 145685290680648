function customAccordion(elements, trigger, openOne = false) {
    if (openOne) {
        elements[0].classList.add('is-active');

        elements.forEach(accordion => {
            const triggerBtn = accordion.querySelector(trigger);
           
            triggerBtn.addEventListener('click', () => {
                elements.forEach(accordion => {
                    accordion.classList.remove('is-active');
                })

                accordion.classList.add('is-active');
            })
        })
    } else {
        elements.forEach(accordion => {
            const triggerBtn = accordion.querySelector(trigger);
            triggerBtn.addEventListener('click', () => {
                accordion.classList.toggle('is-active');
            })
        })
    }
}

if (document.querySelector(".accordion__item")) {
    const accordions = document.querySelectorAll('.accordion__item');
    customAccordion(accordions, '.accordion__head', true)
}
if (document.querySelector(".job-list__accordion-box")) {
    const accordions = document.querySelectorAll('.job-list__accordion-box');
    customAccordion(accordions, '.job-list__head')
}
