const brandsAccordions = document.querySelectorAll('.brands__col');

function closeHandler (element) {
    element.classList.remove('is-active');
}
if(brandsAccordions.length) {
    brandsAccordions.forEach(element => {

        const btn = element.querySelector('.brands__icon');

        if (innerWidth > 1023) {
            brandsAccordions[brandsAccordions.length - 1].classList.add('is-active')
        }
            
        element.addEventListener('click', (e) => {
            if (!element.classList.contains('is-active')) {
                brandsAccordions.forEach(element => {
                    if (element.classList.contains('is-active')) {
                        closeHandler(element)
                    }
                })
                element.classList.add('is-active');
            } else if (e.target === btn && element.classList.contains('is-active') && innerWidth < 1024) {
                closeHandler(element)
            }
        })
    })
}



